.login_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    height: 100vh;
}

.login_page .login_page_form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login_page .login_page_pinbox {
    margin: 20px;
}

.button_wrapper {
    position: relative;
}

.button_progress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -12px;
}
